$congress: #004b84;
$bar-color: #263238;
$easycontrol: #f4623a;

// $easycontrol: #f3463b;
// $easycontrol: #491c60;
// $easycontrol: #38112a; //ubuntu terminal
// $easycontrol: #9c5789; //odoo
// $easycontrol: #004b84; //congress

$wine: #c43256;
$danger: rgb(241, 55, 59);
$success: rgb(25, 135, 84);

$primary: $easycontrol;
$border-radius-tinny: 0.125rem !default;

$form-floating-height: 3.25rem;
$form-floating-padding-y: 0.8rem;
$form-floating-input-padding-t: 1.5rem;
$form-floating-input-padding-b: 0.25rem;
