.fw-600 {
    font-weight: 600;
}

.fshadow-0:focus {
    box-shadow: none;
}

.rounded-sm {
    border-radius: 0.125rem;
}

.abs-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.mw-35 {
    max-width: 35rem;
}
.mw-40 {
    max-width: 40rem;
}
.mw-50 {
    max-width: 50rem;
}

@include media-breakpoint-down(sm) {
    .w-sm-100 {
        width: 100% !important;
    }
}
