.form-control,
.form-select {
    box-shadow: inset 0 0 0.5rem #ebeef4;
    border-radius: $border-radius-tinny;
    &:focus {
        box-shadow: none;
        border-color: $primary;
    }
}

.form-floating > {
    .form-control:focus,
    .form-control:not(:placeholder-shown),
    .form-select {
        ~ label {
            opacity: 0.85;
            transform: scale(0.8) translateY(-.5rem) translateX(0.15rem);
        }
    }
}

.form-floating > label {
    color: $gray-700;
    //  font-size: 0.875em;
}

.form-control ~ .btn-end {
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    transition: padding 0.1s ease-in-out;
}

.form-floating {
    .btn-end {
        position: absolute;
        height: 100%;
        top: 0;
        transition: padding 0.1s ease-in-out;
    }

    & > .form-control:focus ~ .btn-end,
    & > .form-control:not(:placeholder-shown) ~ .btn-end {
        padding-top: $form-floating-input-padding-t;
        padding-bottom: $form-floating-input-padding-b;
    }
}

.v-container {
    border-radius: $border-radius-tinny;

    &.is-invalid,
    &.is-valid {
        padding: 0.0625rem;
        color: $white;
    }
    &.is-invalid {
        // background-color: rgba($danger, 0.9);
        background-color: $wine;
    }

    &.is-valid {
        background-color: $success;
    }
}

//reset bootstrap modal static backdrop effect. removed by dashmix
.modal.modal-static .modal-dialog {
    transform: $modal-scale-transform !important;
}