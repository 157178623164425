/***************  functions and mixins ***************/
//bootstrap
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
//app theme
@import "dashmix/mixins/content";

@import "custom/variables";
@import "~bootstrap/scss/variables";
@import "dashmix/variables-bootstrap";
@import "dashmix/variables";

@import "fonts/source_sans_pro";

/*************** import bootstrap ***************/
@import "~bootstrap/scss/utilities";
// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
// @import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
/* @import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge"; */
@import "~bootstrap/scss/alert";
/* @import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";*/
@import "~bootstrap/scss/close";
/*@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel"; */
@import "~bootstrap/scss/spinners";
// @import "~bootstrap/scss/offcanvas";
// @import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
// scss-docs-end import-stack

/*************** import custom bootstrap ***************/
@import "custom/bootstrap/reboot";
@import "custom/bootstrap/utilities";
@import "custom/bootstrap/buttons";
@import "custom/bootstrap/form";

/*************** import theme ***************/
@import "dashmix/layout";


.hero{
  background-image: linear-gradient(rgba($black, 0.7), rgba($black, 0.85),
   rgba($black, 0.7)),url('/img/shutterstock_571884931-scaled.jpg');
   background-size: cover;
   background-position: top right ;
}


@keyframes zoomIn {
  80%{
      transform: scale(1.05);  
  }
  100% {
    transform: scale(1);
  }
}

.zoom-in {  
  /* This section calls the slideInFromLeft animation we defined above */
  animation: .3s ease-out 0s 1 zoomIn;
}
